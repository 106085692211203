/* eslint max-len: 0 */ // --> OFF

export default {
  /** ****** */
  /** GLOBAL */
  /** ****** */

  global: {
    appName: 'Stock Finder',
    appNameCanonical: 'Stock Finder',
    appNameGroup: 'Domingo Alonso',
  },

  button: {
    add: 'Add',
    accept: 'Accept',
    account: 'My Account',
    back: 'Back',
    backToInit: 'Back to Home',
    buy: 'Buy',
    cancel: 'Cancel',
    clear: 'Clear',
    create: 'Create',
    close: 'Close',
    connect: 'Connect',
    confirm: 'Confirm',
    configuration: 'Configuration',
    contact: 'Contact',
    continue: 'Continue',
    delete: 'Delete',
    edit: 'Edit',
    eraseEverything: 'Erase everything',
    exit: 'Exit',
    forgotPassword: 'Forgot your password?',
    goToStore: 'Go to {{store}}',
    help: 'Help',
    info: 'Info',
    imInterested: 'I\'m interested',
    loading: 'Loading...',
    login: 'Log in',
    logout: 'Logout',
    ok: 'ok',
    menu: 'menu',
    modify: 'Modify',
    moreInformation: 'More information',
    no: 'No',
    pay: 'Pay',
    payment: 'Payment',
    register: 'Register',
    rememberPassword: 'Remember password',
    restartApp: 'Restart app',
    restore: 'Restore',
    retry: 'Retry',
    returnsExchanges: 'Returns & Exchanges',
    save: 'Save',
    send: 'Send',
    sendRequest : 'Send request',
    sendRequest2 : 'Send request',
    search: 'Search',
    shop: 'Shop',
    update: 'Update',
    yes: 'Yes',
  },

  error: {
    appCrash: 'Oops!, something went wrong.\nWe apologize for this.\nPress the button to restart the app.',
    completeThisField: 'Please complete this field.',
    linkingError: 'An error occurred trying to open the link',
    networkError: 'Oops! It seems that there is a problem with the Internet connection. Check the connection and retry again',
    networkErrorShort: 'You\'re offline, Please check your Internet connection',
    server400: 'Oops! It seems that something has not gone well. Please, try again or later',
    server401: '¡Oops! It seems that we haven\'t could validate your credentials. Please, close session and introduce your credentials again',
    server500: 'Oops! It seems that something has not gone well. Please, try again later',
    sessionExpireTitle: 'Session expired',
    sessionExpireMessage: 'Your session have expired. Please, login again to continue using the application',
  },

  tooltip: {
    addComparer: 'Add to comparer',
    sendToEmail: 'Send by email',
    removeComparer: 'Remove from comparator',
  },

  /** *** */
  /** APP */
  /** *** */

  form: {
    error: {
      invalidAddress: 'Invalid address',
      invalidCardCVV: 'Invalid CVV',
      invalidCardExpireMonth: 'Invalid month',
      invalidCardExpireYear: 'Invalid year',
      invalidCardHolder: 'Invalid card holder',
      invalidCardNumber: 'Invalid card number',
      invalidCity: 'Invalid city',
      invalidContactMethod: 'Invalid method contact',
      invalidDay: 'Invalid day',
      invalidEmail: 'Invalid email',
      invalidGender: 'Select an option',
      invalidLastName1: 'Invalid lastname 1',
      invalidLastName2: 'Invalid lastname 2',
      invalidMessage: 'Invalid message. Minimum 100 characters',
      invalidMonth: 'Invalid month',
      invalidName: 'Invalid name',
      invalidPasswordCoincidence: 'The passwords do no match',
      invalidPasswordLength: 'The password must have at least 6 characters',
      invalidPhoneNumber: 'Invalid phone number',
      invalidPostalCode: 'Invalid postal code',
      invalidProvinceSelected: 'Select a province',
      invalidSize: 'Invalid size',
      invalidSubject: 'Invalid subject',
      invalidTaxNumber: 'Invalid NIF / NIE / ID',
      invalidValue: 'This field can\'t be empty',
      invalidYear: 'Invalid year',
      selectOption: 'Select an option',
      selectSchool: 'Select a school',
    },
  },

  placeholder: {
    concessionaire: 'Concessionaire',
    email: 'Email',
    island: 'Island',
    name: 'Name',
    phone: 'Phone',
    model: 'Model',
    searchBox: 'Search by name or reference ...',
    selectConcessionaire: 'Select your concessionaire',
    selectConcessionaire2: 'Select concessionaire',
    select: 'Select...',
    selectIsland: 'Select your island',
    selectModel: 'Select your model',
    surnames: 'Surnames',
  },

  title: {
    help: 'help',
    home: 'Home',
    vehicles: 'Vehicles',
    sections: 'sections',
  },

  /** ******* */
  /** MODULES */
  /** ******* */

  comparator: {
    color: 'Color',
    emptyList: 'You have not yet added vehicles to compare',
    quickFiltering: 'Quick filtering:',
    price: 'Price',
  },

  contact: {
    askInformationServices: 'Ask for information about our services',
    calculateCustomFee: 'Calculate your custom fee',
    contactAdvisor: 'Contact an advisor',
    customCommercialCommunications: 'We want to adapt our personalized Commercial Communications according to your tastes, preferences and analysis of your profile',
    financialCalculator: 'Financial calculator data',
    notSeeQuota: `I don't want to see quota`,
    readAgree: 'I have read and accept the',
    receiveCommercialCommunications: 'Would you like to receive commercial communications from Domingo Alonso Group?',
    share: 'Share',
    privacyPolicy: 'Privacy Policy',
    legalText1: {
      moreInfo: '(+ Info)',
      paragraph1: 'Basic information on Data Protection:',
      paragraph2: 'Responsible: {{brand}} CANARIAS S.L.U',
      paragraph3: 'Purpose: Management of inquiries and requests for information.',
      paragraph4: 'Rights: you can exercise your right of access, rectification, deletion and others, as it appears in the extended information that you can know by visiting our',
      privacyPolicy: 'privacy policy',
    },
    legalText2: {
      moreInfo: '(+ Info)',
      paragraph2: 'Responsible: DOMINGO ALONSO GROUP S.L.',
      paragraph3: 'Purpose: Sending advertising information and preparation of commercial profiles.',
      paragraph4: 'Rights: you can exercise your right of access, rectification, deletion and others, as it appears in the extended information that you can find on our website',
      paragraph5: '*This section is not essential to provide the requested services',
      webDomingoAlonso: 'www.domingoalonsogroup.com',
    },
  },

  navBar: {
    backToSite: ' Back to site',
    contactAdvisor: ' Contact advisor',
    comparator: ' Comparator ({{value}})',
    promotions: ' Promotions',
    searchFilter: ' Search/Filter',
    viewStock: ' View stock',
  },

  maintenance: {
    siteUnderMaintenance: "Site under maintenance",
    backSoon: "We will be back soon"
  },

  msgConfirmation: {
    backHomepage: 'Back to homepage',
    msgSent: 'Your message has been sent successfully.',
    msgSent2: 'A specialized agent will contact you within 24 hours.',
    thank: 'Thank you',
  },

  modals: {
    comparatorFull: {
      body: 'The comparer has a limit of {{value}} vehicles, you can delete some if you want to add this one to the list.',
      txtAccept: 'Go to comparator',
      txtCancel: 'OK',
      title: 'Comparator full',
    }
  },

  modalOptions: {
    askDate: 'Request a dealer appointment',
    comparator: 'Comparator',
    contactAdvisor: 'Contact an advisor',
    selectOption: 'Select option',
  },

  models: {
    backToTheList: 'Back to list of',
    choosePrefences: 'Choose your preferences',
    emptyList: 'There are currently no vehicles available',
    contactAdvisor: 'Contact advisor',
    customizeFinancing: 'Customize your financing',
    customizeBrand: 'Customize your {{brand}}',
    customizeQuotas: 'Customize quotas',
    customizeYourModel: 'Customize your model',
    from: 'From ',
    from2: ' / month*',
    monthlyFee: 'Monthly fee',
    notFoundLooking: 'Do not you find what you are looking for?',
    brandAreYouLooking: 'What {{brand}} are you looking for?',
    pvp: 'PVP',
    pvp2: '{{price}} €',
    seeInStock: 'Check our stock',
    stockBrand: 'Stock {{brand}} Canarias',
    spotPrice: 'Sale price **',
    subjectToFinal: 'Subject to final dealer offer',
    tmpOutStock: 'Temporarily out of stock',
    tmpOutStock1: 'Temporarily',
    tmpOutStock2: 'out of stock',
  },

  page404:{
    pageNotFound: "Page not found",
    pageNotFoundDescription: "Sorry, the page you are looking for does not exist."
  },

  toasts: {
    vehicle: {
      addVehicle: 'Vehicle added',
      nameVehicleAdd: 'The vehicle {{vehicle}} ({{price}}) has been added to the comparer successfully',
      notAddVehicle: 'The vehicle {{vehicle}} ({{price}}) is already in the comparer',
      notAddVehicleTitle: 'Vehicle not added',
    },
    alert: {
      WeSorry: 'We\'re sorry'
    }
  },

  vehicles: {
    cuotePrice: 'Maximum quota',
    contactAdvisor: 'Contact an advisor',
    emptyList: 'Currently this model is not available',
    emptyListFilter: 'Currently this model is not available for the indicated filters.',
    highestPrice: 'Highest price',
    lowestPrice: 'Lowest price',
    newInStock: 'new in stock',
    news: 'News',
    maximumPrice: 'Maximum price',
    offers: 'Offers',
    offersPromotions: 'Offers and Promotions',
    sortBy: ' Sort by ',
    soonBackInStock: 'Coming soon it will be back in stock, you can contact us and request more information.',
    termsAndConditions: '*Subject to authorization from the collaborating entity. Consult dealer conditions.'
  },

  vehicleDetails: {
    additionalFeatures: 'Additional features',
    campaigns: 'Campaigns',
    characteristics: 'CHARACTERISTICS',
    co2: '{{value}} g/km',
    consumption: 'WLTP consumption',
    consumptionValue: '{{value}} l / 100 km',
    comfort: 'Comfort',
    entry: 'Entry',
    equipment: 'EQUIPMENT',
    exterior: 'Exterior',
    financedCapital: 'Financed capital',
    environmentalBadge: 'Environmental badge',
    emptyListFilter: 'The vehicle is not available',
    emissions: 'WLTP CO2',
    financing: 'FINANCING',
    financingDetails: 'Financing details (estimated)',
    finalFee: 'Final fee',
    gearbox: '{{gearbox}} shift',
    brand: 'Volkswagen ',
    initialEntry: 'Initial entry',
    interior: 'Inside',
    monthlyFee: 'Monthly fee',
    openingCommission: 'Opening Commission',
    optionalEquipment: 'Optional equipment',
    knowMore: 'Do you want to know more? Get to know all the features in detail and convince yourself. ',
    resultMonthFinancing: 'Months (includes last installment)',
    security: 'Security',
    selectedModel: 'Selected model',
    shift: 'Shift',
    simulate: 'Simulate',
    standardEquipment: 'Discover your standard equipment',
    surpriseAllBenefits: 'Surprise yourself with all its benefits',
    totalInstallmentPrice: 'Total installment price',
    priceFinal: 'Final price',
    personalInformation: 'Personal information',
    priceDetail: 'Detailed price',
    postponement: 'Postponement ({{maxValue}} max)',
    power: 'Power',
    powerValue: '{{value}} CV',
    rgpd: 'RGPD',
    tireLabels: 'Tire Labels ',
    wantThis: 'I want this {{vehicle}}!',
    wantToKnowMore: 'You want to know more? Know all the features in detail and convince yourself.',
    xMonths: '{{months}} months',
  },

};
