import {Component} from "react";
import { withRouter } from "react-router-dom";
import { parseQueryParamsUTM } from "../../../../common/utils/Utils";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      window.scrollTo(0, 0);
    }
    if (
      this.props.location.pathname !== prevProps.location.pathname || 
      this.props.location.search !== prevProps.location.search
      ) {
        const { location, history } = this.props;
        if (parseQueryParamsUTM) {
          parseQueryParamsUTM({
            pathname: location?.pathname, 
            search: prevProps?.location?.search, 
            history
          })
        }
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
