import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

//Resources
import Routing from '../routing/Routing';
import {strings} from '../../../../common/resources/locales/i18n';
import { addPixelFacebook, legalScripts_Default } from '../../../../common/utils/Utils';


class Page404Container extends Component {

  async componentDidMount() {
    const { metasHomeTitle, metasHomeDescription, cookiesBannerScript, metricsGtmEnabled, metricsGtmId } = this.props;

    if (legalScripts_Default) legalScripts_Default({
      metasHomeTitle, metasHomeDescription, 
      cookiesBannerScript, metricsGtmEnabled, metricsGtmId
    });

    if (addPixelFacebook) addPixelFacebook(false, false);
  }

  render() {
    return (
      <>
        <main id="main" className="pb-0" role="main">
          <section id="page-error">
            <div className="container text-center">
              <div className="display-1 text-sand">404</div>
              <h1 className="text-primary mb-3">{strings('page404.pageNotFound')}</h1>
              <p className="lead mb-5">{strings('page404.pageNotFoundDescription')}</p>
              <Link to={Routing.home} className="btn btn-sand px-lg-4 text-uppercase">{strings('button.backToInit')}</Link>
            </div>
          </section>
        </main>
      </>
    );
  }
}

const mapStateToProps = ({ AppReducer }) => {
  const { metasHomeTitle, metasHomeDescription, cookiesBannerScript, metricsGtmEnabled, metricsGtmId} = AppReducer;

  return {
    metasHomeTitle, metasHomeDescription, cookiesBannerScript, metricsGtmEnabled, metricsGtmId
  }
};

export default connect(mapStateToProps, null)(Page404Container);
