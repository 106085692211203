import React from 'react';
import {strings} from '../../../../../common/resources/locales/i18n';
import {Link} from 'react-router-dom';

//Components
import { AsyncImage } from '../../../components/AsyncImage';

//Resources
import {getAsset} from '../../../../../common/resources/assets/assets';

const ModelsItemNoStock = (props) => {
  const { description, image, urlRedirect, configuratorText, alt } = props;

  return (
    <div className="col-md-6 col-lg-3">
      <div className="card card-model no-stock mb-4">
        <div className="card-header position-relative pb-0">

          <figure className='embed-responsive embed-responsive-16by9 mb-0'>
            <AsyncImage
              styleContainer='item'
              source={image}
              style='card-img-top embed-responsive-item'
              srcPlaceholder={getAsset('icPlaceholderModel')}
              minHeightLoading='184px'
              alt={alt}
            />
          </figure>
          
        </div>

        <div className="card-body text-center py-2">
          <h3 className="h4 text-muted">{description}</h3>
          <div className="h6 text-muted">{strings('models.tmpOutStock1')} <br className='d-none d-lg-block'/> {strings('models.tmpOutStock2')}</div>
        </div>

        <div className="card-body card-body-hidden">
          <Link to={{ pathname: urlRedirect }} className="btn btn-outline-secondary btn-block" target="_blank" >
            {configuratorText}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ModelsItemNoStock
