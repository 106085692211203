import React from 'react';
import { strings } from '../../../../../common/resources/locales/i18n';
import { Link } from 'react-router-dom';
import Routing from '../../routing/Routing';
import PropTypes from 'prop-types';
import LazyLoad from "react-lazyload";

//Components
import { AsyncImage } from '../../../components/AsyncImage';

//Resources
import {getAsset} from '../../../../../common/resources/assets/assets';

const ModelsItem = (props) => {
  const { bestFinalPrice, description, image, bestCuotaPriceFormat, urlKey, alt } = props;

  return (
    <div className="col-md-6 col-lg-3">
      <div className="card card-model mb-4">
        <div className="card-header position-relative pb-0">

          <figure className='embed-responsive embed-responsive-16by9 mb-0'>
            <LazyLoad>
              <AsyncImage
                styleContainer='item'
                source={image}
                style='card-img-top embed-responsive-item'
                srcPlaceholder={getAsset('icPlaceholderModel')}
                minHeightLoading='184px'
                minWidthLoading='225px'
                alt={alt}
              />
            </LazyLoad>
          </figure>

        </div>

        <div className="card-body text-center py-2">
          <h3 className="h4 text-dark">{description}</h3>
          <div className="h6 text-dark mb-0">
            <small>{strings('models.from')}</small>
            {bestCuotaPriceFormat} {strings('models.from2')}
          </div>
          {bestFinalPrice ?
            <div className="h6 text-muted"><small>{strings('models.pvp')}</small> {bestFinalPrice}</div>
            :
            <div />
          }
        </div>

        <div className="card-body card-body-hidden">
          <Link to={`${Routing.vehicle}${urlKey}`} className="btn btn-primary btn-block">
            {strings('models.seeInStock')}
          </Link>

          {/*<Link to={Routing.nowhere} onClick={() => showModal } role="button" className="btn btn-gold btn-block" data-toggle="modal" data-target="#modalReservar">
            {strings('models.contactAdvisor')}
          </Link>*/}
        </div>
      </div>

    </div>
  )
}

ModelsItem.propTypes = {
  id: PropTypes.string,
  bestFinalPrice: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  vehiclesCount: PropTypes.number,
};

export default ModelsItem
