import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// ALERTS
import { apiGetModels } from '../../../../common/modules/models/ModelsActions';
import { setAppState } from '../../../../common/modules/app/AppActions';

// Resources
import ModelsItem from './components/ModelItem';
import { Loading } from '../../components';
import ModelsManager from '../../../../common/modules/models/ModelsManager';
import { strings } from '../../../../common/resources/locales/i18n';
import ModelsItemNoStock from './components/ModelItemNoStock';
import {functionShowModalBanner} from '../../resources/styles/js/custom';
import { addPixelFacebook, legalScripts_Default } from '../../../../common/utils/Utils';

class ModelsContainer extends Component {

  async componentDidMount() {
    const { metasHomeTitle, metasHomeDescription, cookiesBannerScript,
      metricsGtmEnabled, metricsGtmId, showModalBanner,
      popupShowAfterSeconds, } = this.props;

    if (legalScripts_Default) legalScripts_Default({metasHomeTitle, metasHomeDescription, cookiesBannerScript, metricsGtmEnabled, metricsGtmId});

    if (addPixelFacebook) addPixelFacebook(false, false);

    if (showModalBanner) {
      if (functionShowModalBanner) functionShowModalBanner({time: popupShowAfterSeconds + '000'});
      this._setAppState('showModalBanner', false);
    }
    await this._apiGetModels()
  }

  render() {
    const { configuratorUrl, homeTitle, homeSubtitle, vehicleGeneralBottom, homeH2Title, homePSubtitle } = this.props;

    return (
      <>
      <main id="main" className="pb-0" role="main">

        <header id="header" className="py-lg-5">
          <div className="container text-center">
            <h1 className="mb-2 text-primary">{homeTitle}</h1>
            <h2 className="lead mb-0 text-dark">{homeSubtitle}</h2>
            
          </div>
        </header>

        <section id="models" className="pt-0 pb-5">
          <div className="container">
            <div className="row">
              {this._renderModelsList()}
              <div className='col-12 text-center'>
                <h2 className='h5 mb-0 mt-2 text-secondary'>{homeH2Title}</h2>
                <p className='mb-0 text-dark'>{homePSubtitle}</p>
              </div>
            </div>
          </div>
        </section>

        <section id="cta-footer" style={{backgroundImage: `url(${vehicleGeneralBottom})`}}>
          <div className="container text-center">
            <h2 className="mb-3">{strings('models.notFoundLooking')}</h2>
            <Link to={{pathname: configuratorUrl}} target="_blank" className="btn btn-sand px-lg-4 text-uppercase"> 
              {strings('models.customizeBrand', {brand: strings('vehicleDetails.brand')})}
            </Link>
          </div>
        </section>

      </main >
      </>
    );
  }

  /** PRIVATE METHOD **/

  async _apiGetModels() {
    await this.props.apiGetModels();
  }

  _renderModelsList() {
    const { configuratorUrl, configuratorText, dataModels, isLoadingModels } = this.props;

    if (isLoadingModels) {
      return (
        <div className='w-100 mt-5'>
          <Loading />
        </div>
      )
    }

    if (!dataModels || (dataModels && dataModels.length === 0)) {
      return (
        <div className='d-flex w-100 justify-content-center align-items-center text-center'>
          <p>{strings('models.emptyList')}</p>

        </div>
      )
    }

    return (
      dataModels.map((item) => {
        const bestFinalPrice = ModelsManager.getBestFinalPriceFormat(item);
        const bestPrice = ModelsManager.getBestPrice(item);
        const description = ModelsManager.getDescription(item);
        const id = ModelsManager.getId(item);
        const image = ModelsManager.getImage(item);
        const vehiclesCount = ModelsManager.getVehiclesCount(item);
        const bestCuotaPriceFormat = ModelsManager.getBestCuotaPriceFormat(item);
        const urlKey = ModelsManager.getUrlKey(item);
        const altImg = ModelsManager.getImageAlt(item)

        if (vehiclesCount === 0) {
          return (
            <ModelsItemNoStock key={id.toString()}
              bestFinalPrice={bestFinalPrice}
              bestPrice={bestPrice}
              alt={altImg}
              description={description}
              id={id}
              image={image}
              urlRedirect={configuratorUrl}
              configuratorText={configuratorText}
            />
          )
        }

        return (
          <ModelsItem key={id.toString()}
            bestFinalPrice={bestFinalPrice}
            description={description}
            alt={altImg}
            id={id}
            image={image}
            bestCuotaPriceFormat={bestCuotaPriceFormat}
            urlKey={urlKey ? urlKey : id}
          />
        )
      })
    )
  }

  _setAppState(prop, value) {
    this.props.setAppState({prop, value});
  }
}

const mapStateToProps = ({ AppReducer, ModelsReducer }) => {
  const { configuratorUrl, configuratorText, homeTitle,
    homeSubtitle, metasHomeTitle, metasHomeDescription,
    vehicleGeneralBottom, cookiesBannerScript, metricsGtmEnabled,
    metricsGtmId, showModalBanner, popupShowAfterSeconds,
    showModalBannerTimestamp, homeH2Title, homePSubtitle } = AppReducer;
  const { dataModels, isLoadingModels } = ModelsReducer;

  return {
    configuratorUrl, configuratorText, homeTitle,
    homeSubtitle, metasHomeTitle, metasHomeDescription,
    vehicleGeneralBottom, cookiesBannerScript, metricsGtmEnabled,
    metricsGtmId, showModalBanner, popupShowAfterSeconds,
    showModalBannerTimestamp, homeH2Title, homePSubtitle,
    dataModels, isLoadingModels
  }
};

const mapStateToPropsAction = {
  apiGetModels, setAppState
};

export default connect(mapStateToProps, mapStateToPropsAction)(ModelsContainer);

