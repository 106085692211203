import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAsset } from '../../../../common/resources/assets/assets';
import { ImageSvg } from '../../components/ImageSvg';
import { strings } from '../../../../common/resources/locales/i18n';

//Components
import ModalOptions from './ModalOptions';
import ModalReservation from '../vehicles/ModalReservation';

class FooterContainer extends Component {

  render() {
    const { dataVehicleDetail, footerLegalTitle, footerHelpLinks, footerLegalText, footerSectionsLinks, footerBanner, history, footerLegalLinks, openConsentsScript } = this.props;

    return (
      <>
        <footer id="footer" className="mt-auto border-top bg-white py-4">

          <div id="prefooter" className="py-5 text-dark">
            <div className="container">
              <div className="row">

                <div className='col-lg-2 mb-5 mb-lg-0'>
                  <div className="mb-3 footer-brand">
                    <ImageSvg src={getAsset("icVolkswagenFooter")} />
                  </div>
                </div>

                <div className='col-lg-2 col-md-6 mb-5 mb-lg-0'>
                  <div className="h6 text-uppercase text-secondary mb-3 small">{strings('title.sections')}</div>
                  <span dangerouslySetInnerHTML={{ __html: footerSectionsLinks }} />
                </div>

                <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
                  <div className="h6 text-uppercase text-secondary mb-3 small">{strings('title.help')}</div>
                  <span dangerouslySetInnerHTML={{ __html: footerHelpLinks }} />
                </div>

                {/* <div className="col-lg-5">
                  <div className="card bg-">
                    <div className="card-body p-5">
                      <div className="h6 text-uppercase text-muted text-center">
                        <span dangerouslySetInnerHTML={{ __html: footerBanner }} />
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-5">
                  <span dangerouslySetInnerHTML={{ __html: footerBanner }} />
                </div>

              </div>
            </div>
          </div>

          <div id="copyright" className="container text-muted pt-2 pb-3">
            <div className="row">
              <div className="col-12 col-lg-9 mb-3 mg-lg-0">
                <span
                  dangerouslySetInnerHTML={
                    {__html: footerLegalLinks.replace('<open_consents_script>', openConsentsScript)}
                  }
                >
                </span>
              </div>
              {/*<div className="col-12 col-md-3 text-md-right">
                by <a href="#" className='text-light'>S!ngular Ecommerce</a>
              </div>*/}
            </div>
          </div>
        </footer>

        <section id="legal_footer" className="py-3 bg-white border-top">
          <div className="container text-muted small pt-4 pb-4">
            <p className="h6 mb-3">{footerLegalTitle}</p>
            <span dangerouslySetInnerHTML={{ __html: footerLegalText }} />
          </div>
        </section>

{/*        <a href="#" role="button" className="btn btn-gold btn-cta" data-toggle="modal" data-target="#modalOptions">
          <ion-icon name="add-outline" />
        </a>*/}
        {/*<a href="#" className="btn btn-secondary btn-chat" title="Chat Salesforce">¿Hablamos?</a>*/}
        <ModalOptions history={history} />
        <ModalReservation vehicle={dataVehicleDetail} history={this.props.history} />
      </>
    )
  }

}

const mapStateToProps = ({ AppReducer, VehiclesReducer }) => {
  const { footerLegalText, footerLegalTitle, footerSectionsLinks, footerHelpLinks, footerBanner, footerLegalLinks, openConsentsScript } = AppReducer;
  const { dataVehicleDetail } = VehiclesReducer;
  return {
    footerLegalText, footerLegalTitle, footerSectionsLinks, footerHelpLinks, footerBanner, footerLegalLinks, openConsentsScript,
    dataVehicleDetail,
  }

};


export default connect(mapStateToProps)(FooterContainer);
