import React, { Component } from 'react';
import { connect } from 'react-redux';

//Actions
import { addToast } from '../../../../common/modules/toasts/ToastsActions';
import {
  apiGetVehiclePromotion,
  apiGetVehicles,
  apiGetVehiclesSearch,
  clearDataVehicles,
  filterVehicles,
  orderByLowerPrice,
  orderByMorePrice,
  orderByOffersAndPromotions, setVehiclesState
} from '../../../../common/modules/vehicles/VehiclesActions';
import { addComparator } from '../../../../common/modules/comparator/ComparatorActions';

//Components
import { Modal } from '../../components/Modal';
import ModalReservation from './ModalReservation';
import VehiclesItems from './components/VehiclesItems';
import VehiclesFilter from './components/VehiclesFilter';

// Resources
import {
  toggleModal,
  closeModalBackdrop,
  removeTooltips,
  startSelectPicker,
  startTooltips,
} from '../../resources/styles/js/custom';
import VehiclesManager from '../../../../common/modules/vehicles/VehiclesManager';
import { strings } from '../../../../common/resources/locales/i18n';
import { Loading } from '../../components';
import VehiclesFilterManager, { filterTypeVehicles } from '../../../../common/modules/vehicles/VehiclesFilterManager';
import Routing from '../routing/Routing';
import { addPixelFacebook, getVinPixelFacebook, legalScripts_VehiclesContainer } from '../../../../common/utils/Utils';
import { Link } from 'react-router-dom';

class VehiclesContainer extends Component {

  state = {
    vehicle: {}
  }

  async componentDidMount() {
    const { metasModelTitle, currentModelDescription, metasModelDescription, cookiesBannerScript, metricsGtmEnabled, metricsGtmId, allVehiclesMetadesc, allVehiclesMetatitle, idParams, match, promoVehiclesMetadesc, promoVehiclesMetatitle} = this.props;
    const isPromotion = match.path.includes(Routing.vehiclePromotion)

    if (legalScripts_VehiclesContainer) {
      legalScripts_VehiclesContainer({
        metasModelTitle, currentModelDescription, 
        metasModelDescription, cookiesBannerScript, 
        metricsGtmEnabled, metricsGtmId, allVehiclesMetadesc, allVehiclesMetatitle, 
        idParams, isPromotion, promoVehiclesMetadesc, promoVehiclesMetatitle
      });
    }

    await this._clearDataVehicles();
    await this._loadVehicles();
    this._getVehiclesFilter();
    await this._orderByLowerPrice();
    startTooltips();
    startSelectPicker();

    const {dataVehicles} = this.props;
    if (addPixelFacebook) addPixelFacebook(false, false, getVinPixelFacebook(dataVehicles));
  }

  async componentDidUpdate(prevProps) {
    const {
      idParams, match, metasModelTitle, currentModelDescription,
      metasModelDescription, cookiesBannerScript, metricsGtmEnabled, metricsGtmId, location, allVehiclesMetadesc, allVehiclesMetatitle, promoVehiclesMetadesc, promoVehiclesMetatitle
    } = this.props;
    const search = idParams;
    const prevPropsSearch = prevProps.idParams;
    const isPromotion = match.path.includes(Routing.vehiclePromotion)

    if (legalScripts_VehiclesContainer) {
      legalScripts_VehiclesContainer({
        metasModelTitle, currentModelDescription, 
        metasModelDescription, cookiesBannerScript, 
        metricsGtmEnabled, metricsGtmId, allVehiclesMetadesc, allVehiclesMetatitle, 
        idParams, isPromotion, promoVehiclesMetadesc, promoVehiclesMetatitle
      });
    }

    if (search !== prevPropsSearch && location && location.pathname && location.pathname.includes('search=') ) {
      await this._apiGetVehiclesSearch();
      await this._orderByLowerPrice();
      startTooltips();
      startSelectPicker();
    } else if (prevProps.match && prevProps.match.path && match && match.path && prevProps.match.path !== match.path ) {
      if (isPromotion){
        await this._apiGetVehiclePromotion();
        await this._orderByLowerPrice();
      }
      if (match.path.includes(Routing.vehicle) || match.path === Routing.vehiclesAllStock){
        await this._apiGetVehicles();
        await this._orderByLowerPrice();
      }
    }

    const {dataVehicles} = this.props;
    if (addPixelFacebook) addPixelFacebook(false, false, getVinPixelFacebook(dataVehicles));
  }

  componentWillUnmount() {
    this._clearDataVehicles();
  }

  render() {
    const { idParams, currentModelH2Label, currentModelH2Paragraph, allVehiclesH2Title, allVehiclesPSubtitle, dataVehicles, dataVehiclesOriginal } = this.props
    const h2Label = idParams ? currentModelH2Label : allVehiclesH2Title
    const h2Paragraph = idParams ? currentModelH2Paragraph : allVehiclesPSubtitle
    const textLabelParagraph = (
      <div className='mt-3'>
        <h2 className='h4 mb-0 text-secondary'>{h2Label}</h2>
        <p className='mb-0 text-dark'>{h2Paragraph}</p>
      </div>
    )

    let showTextLabelParagraph = <></>
    if (idParams && dataVehicles?.length > 0) {
      showTextLabelParagraph = textLabelParagraph
    } else if (dataVehiclesOriginal?.length > 0) {
      showTextLabelParagraph = textLabelParagraph
    }
  
    
    return (
      <>
        <main id="main" role="main">
          <section id="all-models">
            <div className="container">
              {this._renderTopBar()}
              <div className="row pt-2">
                {this._renderVehicleEmptyList()}
                <div className="col-xl-3 d-none d-xl-block">
                  <div id="sidebar-filters" className="sticky-top" style={{ top: 96 }}>
                    {this._renderVehicleFilter()}
                  </div>
                </div>

                <div className="col-lg-9">
                  {this._renderVehicleList()}
                  {showTextLabelParagraph}
                  
                </div>
                
              </div>
            </div>
          </section>
        </main>
        <button className="d-none" data-toggle="modal" data-target="#modalAddComparator" id='idAddComparator'/>
        {this._renderVehicleModalComparator()}
        <ModalReservation vehicle={this.state.vehicle} history={this.props.history}/>
      </>
    );
  }

  /** PRIVATE METHOD **/
  async _addComparator(vehicle) {
    const { dataComparator, vehiclesCompareMax } = this.props;
    if (dataComparator && dataComparator.length === parseInt(vehiclesCompareMax)) {
      toggleModal('idAddComparator');
    } else {
      await this.props.addComparator(vehicle);
      removeTooltips();
    }
  }

  async _apiGetVehicles() {
    const { idParams } = this.props;

    await this.props.apiGetVehicles(idParams);
    //startCarousel();
  }

  async _apiGetVehiclePromotion() {
    const { idParams } = this.props;
    await this.props.apiGetVehiclePromotion(idParams);
    startSelectPicker()
    //startCarousel();
  }

  async _apiGetVehiclesSearch() {
    const { apiGetVehiclesSearch, location } = this.props;
    let search = false;
    if (location && location.pathname) {
      const split = location.pathname.split('=');
      search = split && split.length === 2 ? split[1] : false;
    }
    if (search) {
      await apiGetVehiclesSearch(search);
      startSelectPicker();
      //startCarousel();
    }
  }

  async _clearDataVehicles() {
    await this.props.clearDataVehicles();
  }

  _getVehiclesFilter() {
    const { availableFilters, queryParams, maxPrice, minPrice, maxCuota, minCuota, } = this.props;

    const price = queryParams.get(filterTypeVehicles.PRICE);
    const specialPrice = queryParams.get(filterTypeVehicles.SPECIAL_PRICE);
    const colors = queryParams.get(filterTypeVehicles.COLORS);
    const fuels = queryParams.get(filterTypeVehicles.FUELS);
    const transmissions = queryParams.get(filterTypeVehicles.TRANSMISSIONS);
    const cuota = queryParams.get(filterTypeVehicles.CUOTA);

    if (cuota && minCuota <= cuota && cuota <= maxCuota) {
      this._handleValueChange('cuota', cuota);
    }

    if (price && minPrice <= price && price <= maxPrice) {
      this._handleValueChange('price', price);
    }

    if (specialPrice && specialPrice === 'true') {
      this._handleValueChange('specialPrice', specialPrice === 'true');
    }

    if (colors && VehiclesFilterManager.getFilterValueByCode(availableFilters, filterTypeVehicles.COLORS, colors)) {
      this._handleValueChange('colors', colors);
    }

    if (fuels && VehiclesFilterManager.getFilterValueByCode(availableFilters, filterTypeVehicles.FUELS, fuels)) {
      this._handleValueChange('fuels', fuels);
    }

    if (transmissions && VehiclesFilterManager.getFilterValueByCode(availableFilters, filterTypeVehicles.TRANSMISSIONS, transmissions)) {
      this._handleValueChange('transmissions', transmissions);
    }

    this.props.filterVehicles()
  }

  _handleValueChange = (prop, value) => {
    this.props.setVehiclesState({ prop, value });
  };

  _handleValueChangeAndFilter = (prop, value) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (prop === filterTypeVehicles.COLORS && !Array.isArray(value)) {
      let { colors } = this.props;
      this._handleValueChange(prop, VehiclesManager.pushColor(colors, value).slice());
    } else {
      this._handleValueChange(prop, value);
    }
    this.props.filterVehicles();
    //startCarousel();
  };

  async _loadVehicles() {
    const { location, match } = this.props;
    if (location && location.pathname && location.pathname.includes('search=')) {
      await this._apiGetVehiclesSearch();
      return;
    }if (match && match.path.includes(Routing.vehiclePromotion)) {
      await this._apiGetVehiclePromotion();
      return;
    }
    await this._apiGetVehicles();
  }

  _onClickAccept() {
    const { history } = this.props;
    history.replace(Routing.comparator);
  }

  _renderVehicleEmptyList() {
    const { dataVehiclesOriginal, isLoadingVehicles, noStockPageTitle, noStockPageMessage } = this.props;

    if (isLoadingVehicles) {
      return (
        <div className='w-100 my-5 py-5'>
          <Loading />
        </div>
      )
    }

    if (!dataVehiclesOriginal || (dataVehiclesOriginal && dataVehiclesOriginal.length === 0) && !isLoadingVehicles) {
      // TODO change text to fire
      return <div className='d-flex w-100 justify-content-center flex-column align-items-center px-4'>
        <div className='h4 text-primary'>{noStockPageTitle ? noStockPageTitle : strings('vehicles.emptyList')}</div>
        <p className='fs-1 text-center' style={{maxWidth: '600px',textAlign: 'justify', fontSize: 'large'}}>
         {noStockPageMessage ? noStockPageMessage : strings('vehicles.soonBackInStock')}
        </p>
        <Link to={Routing.contact} className='btn btn-primary text-uppercase mt-2'>
          {strings('vehicles.contactAdvisor')}
        </Link>
      </div>
    }
  }

  _renderVehicleList() {
    const { dataVehicles, dataVehiclesOriginal, isLoadingVehicles, listBottomText, h2ModelBottom, currentModelDescription } = this.props;

    if (isLoadingVehicles) {
      return (
        <div />
      )
    }

    if (!dataVehiclesOriginal || (dataVehiclesOriginal && dataVehiclesOriginal.length === 0) && !isLoadingVehicles) {
      return <div />
    }

    if (dataVehicles && dataVehicles.length === 0) {
      return <div className='d-flex w-100 justify-content-center'>{strings('vehicles.emptyListFilter')}</div>
    }

     const dataRenderVehicles = dataVehicles.map((item, index) => {
        const id = VehiclesManager.getVin(item);
        const modelGroupDescription = VehiclesManager.getModelGroupDescription(item);
        const productLineDescription = VehiclesManager.getProductLineDescription(item);
        const gearboxDescription = VehiclesManager.getGearboxDescription(item);
        const fuelDescription = VehiclesManager.getFuelDescription(item);
        const colorDescription = VehiclesManager.getColorDescription(item);
        const finalPrice = VehiclesManager.getFinalPrice(item);
        const finalPriceFormat = VehiclesManager.getFinalPriceFormat(item);
        const price = VehiclesManager.getPrice(item);
        const priceFormat = VehiclesManager.getPriceFormat(item);
        const images = VehiclesManager.getImagesList(item);
        const cuotaPriceFormat = VehiclesManager.getCuotaPriceFormat(item);
        const power = VehiclesManager.getPower(item);
        const hasSpecialPrice = VehiclesManager.getHasSpecialPrice(item);
        const modelYear = VehiclesManager.getModelYear(item)

        return (
          <VehiclesItems
            key={index}
            item={item}
            id={id}
            modelGroupDescription={modelGroupDescription}
            productLineDescription={productLineDescription}
            gearboxDescription={gearboxDescription}
            fuelDescription={fuelDescription}
            colorDescription={colorDescription}
            finalPrice={finalPrice}
            finalPriceFormat={finalPriceFormat}
            price={price}
            priceFormat={priceFormat}
            images={images}
            cuotaPriceFormat={cuotaPriceFormat}
            power={power}
            hasSpecialPrice={hasSpecialPrice}
            addComparator={() => this._addComparator(item)}
            showModal={() => {
              this.setState({vehicle: item})
            }}
            modelYear={modelYear}
          />
        )
      })

    return (
      <>
        {dataRenderVehicles}
        {currentModelDescription ? <h2>{h2ModelBottom}</h2> : <></>}
        <p className='text-muted text-center pt-4'>{listBottomText}</p>
      </>

    )
  }

  _renderVehicleFilter() {
    const {
      availableFilters, dataVehicles, isLoadingVehicles, maxPrice, minPrice,
      colors, fuels, transmissions, price, specialPrice, maxCuota, minCuota, cuota
    } = this.props;

    if ((dataVehicles && dataVehicles.length === 0) && (availableFilters && availableFilters.length === 0) || isLoadingVehicles) {
      return <div />
    }

    return (
      <VehiclesFilter
        availableFilters={availableFilters}
        onChange={(prop, value) => this._handleValueChangeAndFilter(prop, value)}
        maxPrice={maxPrice}
        minPrice={minPrice}
        colors={colors}
        fuels={fuels}
        transmissions={transmissions}
        price={price}
        specialPrice={specialPrice}
        maxCuota={maxCuota}
        minCuota={minCuota}
        cuota={cuota}
      />
    )
  }

  _renderVehicleModalComparator() {
    const {vehiclesCompareMax} = this.props;
    return (
      <Modal
        body={strings('modals.comparatorFull.body', {value: vehiclesCompareMax})}
        classBody=''
        classBtnAccept='btn btn-primary'
        classBtnCancel='btn btn-secondary'
        idModal='modalAddComparator'
        onClickAccept={() => {
          if(closeModalBackdrop) {
            closeModalBackdrop();
          }
          this._onClickAccept();
        }}
        showBtnCancel={false}
        txtAccept={strings('modals.comparatorFull.txtAccept')}
        txtCancel={strings('modals.comparatorFull.txtCancel')}
        title={strings('modals.comparatorFull.title')}
      />
    )
  }

  _renderTopBar() {
    const { currentModelDescription, vehiclesCount, isLoadingVehicles, currentModelH2Label, currentModelH1 } = this.props;


    
    if (isLoadingVehicles || vehiclesCount === 0) {
      return (<div />)
    }

    const name = currentModelDescription ? currentModelDescription : strings('title.vehicles')

    return (
     <div id="filters" className="mt-0 mb-4 pt-3 pb-2">
        <div className="form-row d-flex align-items-center">

          {vehiclesCount !== 0 && (
            <div className="col-md-9 col-xl-10 d-flex align-items-start justify-content-center flex-column mb-2 mb-lg-0">
              <div>
                <div className="h3 mb-0 text-secondary mr-2 d-none d-lg-inline-flex">{vehiclesCount ? vehiclesCount : 0}</div>
                <h1 className="h3 mb-0 text-primary d-inline-block">{currentModelH1 ? currentModelH1 : `${name} ${strings('vehicles.newInStock')}`}</h1>
              </div>
              <h2 className='h4 mb-0 text-secondary'>{currentModelH2Label}</h2>
             
          </div>
          )}

          {vehiclesCount !==0 ?
            <div className={"col-md-3 col-xl-2 text-right"}>
            <div className="form-group mb-0">
              <select onChange={(e) => this._onSelect(e.target.value)} defaultValue={'0'}
                      className="form-control selectpicker" id="filterOrder">

                <option value='0' disabled>
                  {strings('vehicles.sortBy')}
                </option>

                <option value='1' onClick={() => {
                  this.props.orderByMorePrice()
                }}>
                  {strings('vehicles.highestPrice')}
                </option>

                <option value='2' onClick={() => {
                  this.props.orderByLowerPrice()
                }}>
                  {strings('vehicles.lowestPrice')}
                </option>

                <option value='3' onClick={() => {
                  this.props.orderByOffersAndPromotions()
                }}>
                  {strings('vehicles.offersPromotions')}
                </option>
              </select>
            </div>
          </div>
          :
            <div/>
          }
        </div>
     </div>
    )
  }

  async _onSelect(value) {
    switch (value) {
      case '1':
        this.props.orderByMorePrice();
        break;
      case '2':
        this.props.orderByLowerPrice();
        break;
      case '3':
        this.props.orderByOffersAndPromotions();
        break;

    }

  }

  _orderByLowerPrice() {
    this.props.orderByLowerPrice();
  }
}

const mapStateToProps = ({AppReducer, ComparatorReducer, VehiclesReducer }) => {
  const { vehiclesCompareMax, listBottomText, metasModelTitle, metasModelDescription, cookiesBannerScript, metricsGtmEnabled, metricsGtmId, noStockPageTitle, noStockPageMessage, allVehiclesMetatitle, allVehiclesMetadesc, promoVehiclesMetadesc, promoVehiclesMetatitle, allVehiclesH2Title, allVehiclesPSubtitle } = AppReducer;
  const {
    availableFilters, dataVehicles, dataVehicleDetail, dataVehiclesOriginal, isLoadingVehicles, vehiclesCount, maxPrice, minPrice,
    colors, fuels, transmissions, price, specialPrice, currentModelDescription, maxCuota, minCuota, cuota, currentModelH2Label, currentModelH2Paragraph, currentModelH1,
    h2ModelBottom
  } = VehiclesReducer;
  const { dataComparator } = ComparatorReducer;

  return {
    vehiclesCompareMax,listBottomText, metasModelTitle, metasModelDescription, cookiesBannerScript, metricsGtmEnabled, metricsGtmId, allVehiclesMetatitle, allVehiclesMetadesc, promoVehiclesMetadesc, promoVehiclesMetatitle, allVehiclesH2Title, allVehiclesPSubtitle,
    dataComparator,
    availableFilters, dataVehicles, dataVehicleDetail, dataVehiclesOriginal, isLoadingVehicles, vehiclesCount, maxPrice, minPrice,
    colors, fuels, transmissions, price, specialPrice, currentModelDescription, maxCuota, minCuota, cuota, currentModelH2Label, currentModelH2Paragraph, currentModelH1,
    noStockPageTitle, noStockPageMessage, h2ModelBottom
  }
};

const mapStateToPropsAction = {
  addToast,
  addComparator,
  apiGetVehicles,
  apiGetVehiclePromotion,
  apiGetVehiclesSearch,
  clearDataVehicles,
  setVehiclesState,
  orderByMorePrice,
  orderByLowerPrice,
  orderByOffersAndPromotions,
  filterVehicles
};

export default connect(mapStateToProps, mapStateToPropsAction)(VehiclesContainer);
