export function addMetricas(metricsGtmId) {
  $(document).ready(function(){
    /* START NOSCRIPT BODY */
    const noscriptCustom = document.createElement("noscript");
    const iframeCustom = document.createElement("iframe");
    iframeCustom.style = 'display:none;visibility:hidden';
    iframeCustom.width = '0';
    iframeCustom.height = '0';
    iframeCustom.src = `https://www.googletagmanager.com/ns.html?id=${metricsGtmId}`;
    noscriptCustom.appendChild(iframeCustom);
    document.getElementsByTagName('noscript')[0].before(noscriptCustom);
    /* END NOSCRIPT BODY */
    /* START SCRIPT HEAD */
    const scriptCustom = document.createElement('script');
    scriptCustom.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${metricsGtmId}')`;
    document.head.appendChild(scriptCustom);
    /* END SCRIPT HEAD */
  });
}

export function addCookies(props) {
  $(document).ready(function(){
    const {cookiesBannerScript} = props;
    const scriptCookies = document.createElement("script");
    scriptCookies.src = cookiesBannerScript;

    document.head.appendChild(scriptCookies);
  });
}

export function addCustomHeadSEO(view, props) {
  $(document).ready(function(){
    const {
      currentModelDescription,
      metasHomeDescription, metasModelDescription,
      metasHomeTitle, metasModelTitle, allVehiclesMetadesc, allVehiclesMetatitle, idParams, isPromotion,
      promoVehiclesMetadesc, promoVehiclesMetatitle,
    } = props;

    const allMetas = document.getElementsByTagName('meta');
    let indexMetaDesc = null;
    for (let i = 0; i < allMetas.length; i++) {
      if (allMetas[i].name === 'description' && indexMetaDesc === null) {
        indexMetaDesc = i;
      } 
    }

    switch (view.toLowerCase()) {
      case 'default':
        document.getElementsByTagName('meta')[indexMetaDesc].content = metasHomeDescription;
        document.getElementsByTagName('title')[0].innerText = metasHomeTitle;
        break;
      case 'vehicles_container':
        if (isPromotion) {
          document.getElementsByTagName('meta')[indexMetaDesc].content = promoVehiclesMetadesc;
          document.getElementsByTagName('title')[0].innerText = promoVehiclesMetatitle;
        } else if (!idParams) {
          document.getElementsByTagName('meta')[indexMetaDesc].content = allVehiclesMetadesc;
          document.getElementsByTagName('title')[0].innerText = allVehiclesMetatitle;
        } else { 
          document.getElementsByTagName('meta')[indexMetaDesc].content = metasModelDescription.replace('<modelo>', currentModelDescription).replace('<marca>', 'Hyundai');
          document.getElementsByTagName('title')[0].innerText = metasModelTitle.replace('<modelo>', currentModelDescription);
        }
        break;
    }
  });
}
