
import {isDev} from '../../utils/Utils';
import {formatImageToSvg} from '../../../web/src/resources/styles/js/custom';

let singleton = {};

const localAssets = {
  imgContact2:      require('./images/bg-contact-2.jpg').default,
  imgBannerDemo:      require('./images/banner-demo.jpg').default,
};

const localSvg = [
  {key: "icAdviser",          value: require('./icons/ic_adviser.svg').default},
  {key: "icAdviser2",         value: require('./icons/ic_adviser_2.svg').default},
  {key: "icCheck",            value: require('./icons/ic_check.svg').default},
  {key: "icComparative",      value: require('./icons/ic_comparative.svg').default},
  {key: "icError",            value: require('./icons/ic_error.svg').default},
  {key: "icPlaceholderModel", value: require('./icons/ic_placeholderModel.svg').default},
  {key: "imgLogoHyundai",     value: require('./images/img_logo_hyundai.svg').default},
  {key: "icSymbolHyundai",    value: require('./icons/ic_symbolHyundai.svg').default},
  {key: "icSymbolVolkswagen",    value: require('./icons/logo-vw.svg').default},
  {key: "icVolkswagenFooter",    value: require('./icons/symbol-vw.svg').default},
]

/** ********* **/
/** SINGLETON **/
/** ********* **/
const getAsset = (svg) => {
  if (!singleton || singleton === {} || !singleton[svg]) {
    if (isDev()) console.log("No singleton - getAssets()");
    getAssets()
  }

  return singleton[svg];
};

const  getAssets = async() => {
  await getIcons(localSvg)
};


const getIcons = async(assetsArray) => {
  return await Promise.all(
    assetsArray.map(async (item) => {
      return {[item.key]: await formatImageToSvg(`<img src=${item.value} class="svg"/>`)}
    })
  ).then( source => {
    source.map((item) => {
      singleton = {...singleton, ...item}
    })
  })
};


export { localAssets, getAsset, getAssets };
